import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Button, Layout, Seo } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import Nav from "components/navigation/Nav"
import MobileNav from "src/components/navigation/mobile/MobileNav"

import { CareersGrid } from "src/sections/careers"
import Footer from "src/components/navigation/Footer"

const CareerPage = ({ data: { careers } }) => {
  const { careers_grid } = useContentfulSection(careers.sections)
  const meta = useMeta(careers)

  const cta = careers_grid.ctas[0]

  return (
    <Layout>
      <Seo {...meta} />
      <Nav />
      <MobileNav />
      <Wrap>
        <CareersGrid {...careers_grid} />
        <div className="buttonWrap">
          <a href={cta.url} target="_blank" rel="noreferrer">
            <Button>{cta.text}</Button>
          </a>
        </div>
      </Wrap>
      <Footer />
    </Layout>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  min-height: calc(var(--vh, 1vh) * 100);

  .buttonWrap {
    text-align: center;
  }
`

export const query = graphql`
  {
    careers: contentfulPage(pageId: { eq: "careers" }) {
      ...Page
    }
  }
`

export default CareerPage
