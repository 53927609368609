import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import map from "lodash/map"

import { device } from "src/utils"
import { Card2, Link, ValueProp, VSection } from "components/common"

const CareersGrid = ({ title, subtitle }) => {
  const { career } = useStaticQuery(CAREER_QUERY)

  const renderCareersGrid = () => (
    <DepartmentGrid>
      {map(career.group, ({ fieldValue, edges }) => {
        return (
          <div className="departmentCol" key={fieldValue}>
            <h3 className="department">{fieldValue}</h3>

            <div className="careerGrid">
              {map(edges, ({ node: { position, slug } }) => (
                <Link to={`/careers/${slug}`} className="position-card">
                  <Card2>
                    <ValueProp
                      className="vp-position"
                      key={slug}
                      orientation="horizontal"
                      subtitle={position}
                      arrow
                    />
                  </Card2>
                </Link>
              ))}
            </div>
          </div>
        )
      })}
    </DepartmentGrid>
  )

  return (
    <StyledCareersGrid>
      <VSection
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        custom={renderCareersGrid}
      />
    </StyledCareersGrid>
  )
}

const StyledCareersGrid = styled.div`
  --section-padding-top: var(--sp-nav);
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-max-width: 1080px;

  .title,
  .subtitle {
    --tg-text-align: center;
    --tg-max-width: 600px;
    text-align: center;
    margin: 0 auto;
  }

  .customWrap {
    margin-top: var(--sp-64);
  }
`

const DepartmentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--sp-48);

  .departmentCol {
    .department {
      margin: var(--sp-32) 0;
    }

    .careerGrid {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--sp-16);
      --vp-col: 1fr auto;
      --vp-align: center;
      --tg-text-align: left;

      .position-card {
        @media ${device.laptop} {
          transition: 0.2s opacity ease-in-out;

          &:hover {
            opacity: 0.8;

            .arrow-right {
              transform: translateX(10px);
            }
          }
        }

        .vp-position {
          grid-template-columns: 1fr 16px;
          align-items: center;

          .subtitle {
            margin: 0;
            --tg-text-align: left;
            text-align: left;
          }

          .arrow-right {
            transition: 0.2s transform ease-in-out;
          }
        }
      }
    }
  }
`

export const CAREER_QUERY = graphql`
  {
    career: allContentfulCareer(sort: { fields: department, order: DESC }) {
      group(field: department) {
        fieldValue
        edges {
          node {
            ...Career
          }
        }
      }
    }
  }
`

export default CareersGrid
